


























































































































































































































































































































































































































































import { InputSetups } from "@/mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import XpHandlerModuleBuilder from "@/includes/logic/Modules/models/modules/XpHandlerModule/XpHandlerModuleBuilder";
import EditorButtonsHelpView from "@/includes/helpViews/editorButtonsHelpView.vue";
import Placeholders from "@/mixins/placeholders/placeholders";
import GoBackButton from "@/components/Modules/components/PageTitleButtons/GoBackButton.vue";
import SaveModuleButton from "@/components/Modules/components/PageTitleButtons/SaveModuleButton.vue";
import ModuleBindings from "@/includes/logic/Modules/mixins/ModuleBindings";
import ModuleConfigSettings from "@/components/Modules/components/ModuleConfigSettings.vue";
import ModuleConfigSettingsWrapper from "@/components/Modules/components/ModuleConfigSettingsWrapper.vue";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";

import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue';
import ExtendedHelpMessage from "piramis-base-components/src/components/ExtendedHelpMessage.vue";
import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import { MediaEditorMode } from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";
import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator';
import { isEmpty } from "lodash";

@Component({
  components: {
    NotAvailableOptionsOverlay,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SaveModuleButton, GoBackButton,
    CenteredColumnLayout,
    PageTitle,
    ExtendedHelpMessage,
    NumberInput,
    MultiMessageEditorWithMediaInput,
    ConfigField,
    NestedContent,
    TimeUnitInput
  },
  data() {
    return {
      MediaEditorMode,
      EditorButtonsHelpView
    }
  }
})
export default class XpHandlerModuleView extends Mixins(InputSetups, TariffsTagsHelper, Placeholders, ModuleBindings) {
  @VModel() module!: XpHandlerModuleBuilder

  get tariffTag() {
    return this.module.tariffTags()
  }

  get xp_action_jack_pot_chance_computed(): number {
    return this.module.model.params.xp_action_jack_pot_chance / 10;
  }

  set xp_action_jack_pot_chance_computed(value: number) {
    this.module.model.params.xp_action_jack_pot_chance = parseFloat(value.toFixed(1)) * 10
  }

  created() {
    if (isEmpty(this.module.model.params.xp_action_reward) && this.defaultModuleConfigModel && this.defaultModuleConfigModel.params) {
      const params = this.defaultModuleConfigModel.params

      this.$set(this.module.model.params, 'xp_action_reward', params.xp_action_reward)
    }
  }

  mounted() {
    if (this.tariffTag) {
      this.$baseTemplate.saveButton.hide()
    }
  }
}
